<template>
	<div id="dialog">
		<h3>B2 Images</h3>
		<div>
			<img id="preview" :src="urls['lo-res']" />
		</div>
		<ul>
			<li v-for="(size, i) in sizes" :key="i">
				<a target="_blank" :href="urls[size]">{{ size }}</a>
				<template v-if="size == 'original'">.{{ ogExt }} (remains)</template>
				<template v-else>.{{ ext }}</template>
			</li>
		</ul>
		<br />
		<FormDropdown label="Format" v-model="newExt" :options="ddOptions" />
		<div v-if="errorMsg" class="error-msg small">{{ errorMsg }}</div>
		<div v-if="successMsg" class="ok-msg small">{{ successMsg }}</div>
		<div class="buttons-wrap">
			<FormButton
				:level="1"
				:value="['Regenerate', 'Regenerating']"
				:wait="waiting"
				@click="regenerateB2Images"
			/>
			<FormButton :level="2" value="Done" @click="doCancel" />
		</div>
	</div>
</template>

<script>
// Vue
import { mapState } from 'pinia'

// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormDropdown from '@/components/FormDropdown'
import FormButton from '@/components/FormButton'

// Internal
import { handleApiError } from '@/use/Helpers'

export default {
	name: 'DialogB2Images',
	components: { FormDropdown, FormButton },
	setup() {
		const apiStore = useApiStore()
		const itmApi = apiStore.loadApi('itm')
		return { itmApi }
	},
	data() {
		return {
			// v-model for the dropdown
			newExt: null,

			sizes: ['original', 'full-res', 'hi-res', 'lo-res', 'thumb'],
			urls: {},
			ext: null,
			ogExt: null,
			successMsg: null,
			errorMsg: null,
			waiting: false,

			ddOptions: [
				{
					value: 'webp',
					display: 'WebP',
					selected: true,
				},
				{
					value: 'png',
					display: 'PNG',
				},

				// Gif not yet supported - see resize.js
				// {
				// 	value: 'gif',
				// 	display: 'GIF',
				// },

				// Not sure if there will ever be any value to export JPGs...
				// {
				// 	value: 'jpeg',
				// 	display: 'JPEG',
				// },
			],
		}
	},
	computed: {
		// Pinia state
		...mapState(useDialogStore, ['params', 'doCancel']),
		viewNr() {
			return this.params.viewNr || 0
		},
	},
	async created() {
		this.loadArtworkView()
	},
	methods: {
		// Load artwork & view
		async loadArtworkView() {
			const { itmId } = this.params
			if (!itmId) {
				this.errorMsg = 'No itm id has been specified.'
				return
			}
			const { data: artwork } = await this.itmApi.getById(itmId)
			console.log({ artwork })
			const view = artwork.views[this.viewNr]
			let { id: viewId, fnStm } = view
			this.ext = view.ext
			this.ogExt = view.ogExt
			const bucket = artwork.dev ? 'arthur-b2-staging' : 'arthur-b2'

			// Legacy Curiator artworks have a fnStm set, new upload just have a view id
			fnStm = fnStm ? fnStm : `${itmId}.${viewId}`

			this.sizes.forEach(size => {
				const ext = size == 'original' ? this.ogExt : this.ext
				const fileName = fnStm + '.' + ext
				this.urls[size] = this.getUrl(bucket, size, fileName)
			})
		},

		getUrl(bucket, size, fileName) {
			return `https://f000.backblazeb2.com/file/${bucket}/art/${size}/${fileName}`
		},

		async regenerateB2Images() {
			this.successMsg = null
			this.error = null
			this.waiting = true
			const { status, data, statusText } = await this.itmApi.regenerateB2Images(
				this.params.itmId,
				this.viewNr,
				this.newExt
			)
			this.waiting = false
			if (status == 200) {
				this.successMsg = data
				this.loadArtworkView()
			} else {
				handleApiError({ status, data, statusText, action: 'regenerating images', noFlash: true })
				//
				this.error = statusText
			}
		},
	},
}
</script>

<style scoped lang="scss">
#preview {
	border-radius: $br;
	margin-bottom: 0.2rem;
	max-width: 100%;
	max-height: 2rem;
}
.error-msg,
.ok-msg {
	margin-top: 0.2rem;
}
</style>
